import React from 'react';
import { useUserContext } from '../contexts/userContext';
import Can from '../components/utilities/Can';
import { Redirect } from 'react-router-dom';
import TeamPageWrapper from '../components/team/TeamPageWrapper';

const Team = ({ match }) => {
	const { userData } = useUserContext();

	return (
		<>
			{userData && (
				<Can
					role={userData.currentUserRole}
					perform="team:visit"
					data={{
						roles:
							userData.teams &&
							userData.teams.length > 0 &&
							userData.teams.filter(
								(item) => item.id === match.params.teamId * 1
							).length > 0
								? userData.teams.filter(
										(item) => item.id === match.params.teamId * 1
								  )[0].teamMember.roles
								: [],
					}}
					yes={() => <TeamPageWrapper match={match} />}
					no={() => <Redirect to="/" />}
				/>
			)}
		</>
	);
};

export default Team;
