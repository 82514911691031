import useCurrentTeam from './useCurrentTeam';

const useFlexPot = () => {
	const team = useCurrentTeam();
	let flexPot = null;
	const flexPotTeams = {
		1049: true,
		1121: true,
		1122: true,
	} as any;
	if (flexPotTeams[team.id]) {
		flexPot =
			(team.teamMember.subStatus === 'active' &&
				team.budgets.find(({ name }: any) => name.indexOf('Wellness') !== -1)
					?.id) ||
			-1;
	}
	return flexPot;
};

export default useFlexPot;
