/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from './react-auth0-spa';
import config from '../config';
import client from '../api/client';
import useClient from '../hooks/useClient';

export const UserContext = React.createContext();
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
	const [userData, setUserData] = useState();
	const [walletPoints, setWalletPoints] = useState(0);
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const clientNew = useClient();
	const { user, getTokenSilently, loginWithRedirect, isAuthenticated } =
		useAuth0();

	useEffect(() => {
		const createSession = async () => {
			try {
				if (user && isAuthenticated) {
					let token;
					try {
						token = await getTokenSilently();
					} catch (e) {
						if (e.error === 'login_required') {
							loginWithRedirect();
						}
						if (e.error === 'consent_required') {
							loginWithRedirect();
						}
						throw e;
					}
					let firstName = user.given_name;
					let lastName = user.family_name;
					if (!user.given_name) {
						const value = `; ${document.cookie}`;
						const parts = value.split(`; marketingSignUp=`);
						if (parts.length === 2) {
							const marketingCookie = parts.pop().split(';').shift();
							({ firstName, lastName } = JSON.parse(marketingCookie));
						}
					}
					client.defaults.headers.Authorization = `Bearer ${token}`;

					const response = await client.post('/auth/login', {
						firstName,
						lastName,
						email: user.email ? user.email : null,
					});

					let payload = response.data;

					if (user.appState) {
						if (
							user.appState.status === 'Open' &&
							(user.appState.email.toLowerCase() === user.email.toLowerCase() ||
								user.appState.email === 'pinnacle invite')
						) {
							await client.put(
								`${config.users.HOST}/users/invite/${user.appState.hash}`
							);
							const responseProfile = await client.get(`/users`);
							payload = {
								...responseProfile.data,
							};
						}
					}
					const currentTeam = payload.teams.filter(
						(team) => team.id === payload.currentTeam
					)[0];
					payload.currentTeam = currentTeam ? payload.currentTeam : null;
					setUserData(payload);
					if (
						window.location.hash.includes('access_token=') ||
						window.location.pathname === '/login' ||
						window.location.pathname === '/signup'
					) {
						if (currentTeam?.teamMember.roles.indexOf('owner') > -1) {
							if (currentTeam.engagementReport?.aboveThreshold)
								history.replace(`/team/${currentTeam.id}/engagement`);
							else history.replace(`/team/${currentTeam.id}/team`);
						} else {
							history.replace(`/`);
						}
					}
				} else if (isAuthenticated === false) {
					setUserData({
						// currentTeam: null,
						teams: [],
					});
				}

				setLoading(false);
			} catch (error) {
				console.error(error);
			}
		};
		createSession();
	}, [user, isAuthenticated, getTokenSilently, loginWithRedirect]);

	useEffect(() => {
		try {
			if (userData) {
				setWalletPoints(
					userData.points +
						userData.teams.reduce((acc, cur) => {
							return cur &&
								cur.teamMember.roles.includes('member') &&
								cur.id === userData.currentTeam
								? acc + cur.teamMember.points
								: acc;
						}, 0)
				);
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [userData]);

	const updateUserData = async () => {
		try {
			const response = await clientNew.get(`/users`);
			setUserData({
				...response.data,
			});
			return response.data;
		} catch (err) {
			console.error(err.message);
		}
	};

	const updateUserAPI = useCallback(
		async (data) => {
			await clientNew.put(`${config.api.HOST}/users`, data);
		},
		[clientNew, userData]
	);

	// Update user field
	const updateUserFields = async (data) => {
		try {
			const dataSet = {
				[data.field]: data.value,
			};
			await updateUserAPI(dataSet);
			const newUser = { ...userData };
			newUser[data.field] = dataSet[data.field];
			setUserData(newUser);
		} catch (err) {
			console.error(err.message);
		}
	};

	// Update user field
	const updateUserFieldsBatch = async (data) => {
		try {
			const dataSet = data.reduce(
				(acc, cur) => ({
					...acc,
					[cur.field]: cur.value,
				}),
				{}
			);
			await updateUserAPI(dataSet);
			const newUser = { ...userData };
			data.forEach((item) => {
				newUser[item.field] = item.value;
			});
			setUserData(newUser);
		} catch (err) {
			console.error(err.message);
		}
	};

	const createBookingUser = async (data) => {
		try {
			const bookingData = {
				...data,
				product: {
					id: data.product.id,
					title: data.product.title,
					provider: {
						id: data.product.provider.id,
						title: data.product.provider.title,
					},
				},
			};
			const response = data.service.enquire
				? await clientNew.post(
						`${config.api.HOST}/bookings/enquiry`,
						bookingData
				  )
				: await clientNew.post(`${config.api.HOST}/bookings/`, bookingData);
			if (response.data) {
				if (response.data.status === 'success') {
					updateUserData();
				}
				return response.data;
			}
			return null;
		} catch (err) {
			console.error(err.message);
		}
	};

	const cancelBookingUser = async (booking, admin) => {
		try {
			if (booking.productId === config.customPages.reimbursement.PRODUCT) {
				await client.delete(
					`${config.platform.HOST}/payments/authorized/reimbursements/${booking.id}`
				);
				updateUserData();
				return { status: 'success' };
			}
			const token = await getTokenSilently();
			client.defaults.headers.Authorization = `Bearer ${token}`;
			const response = await client.put(
				`${config.api.HOST}/bookings/${booking.id}/cancel`
			);
			if (response.data) {
				if (!admin) {
					if (response.data.status !== 'failure') {
						updateUserData();
					}
				}
				return {
					status: response.data.status,
					message: response.data.message,
				};
			}
			return false;
		} catch (err) {
			console.error(err.message);
		}
	};

	// Change current user team
	const changeCurrentTeam = async (teamId) => {
		try {
			const dataSet = {
				currentTeam: teamId ? teamId : null,
			};
			await updateUserAPI(dataSet);
			const newUser = { ...userData };
			newUser.currentTeam = dataSet.currentTeam;
			setUserData(newUser);
		} catch (err) {
			console.error(err.message);
		}
	};

	// Update default card
	const updateDefaultPaymentMethodTeam = async (pmId, teamId) => {
		try {
			// Update on stripe and API
			await clientNew.put(
				`${config.api.HOST}/teams/default-payment-method/${teamId}`,
				{
					pmId,
				}
			);
			updateUserData();
		} catch (err) {
			console.error(err.message);
		}
	};

	// Update cards
	const addPaymentMethodTeam = async (pmId, teamId, makeDefault) => {
		try {
			await clientNew.post(
				`${config.api.HOST}/teams/add-payment-method/${teamId}`,
				{
					pmId,
					makeDefault,
				}
			);
			updateUserData();
			return true;
		} catch (err) {
			console.error(err.message);
			return false;
		}
	};

	const deletePaymentMethodTeam = async (pmId, teamId) => {
		try {
			await clientNew.put(
				`${config.api.HOST}/teams/delete-payment-method/${teamId}`,
				{
					pmId,
				}
			);
			updateUserData();
		} catch (err) {
			console.error(err.message);
		}
	};

	const changeFavorite = async (productId) => {
		try {
			// eslint-disable-next-line no-nested-ternary
			const favorites = !userData.favorites
				? [productId]
				: userData.favorites.filter((item) => item === productId).length !== 0
				? userData.favorites.filter((item) => item !== productId)
				: [...userData.favorites, productId];
			await clientNew.put(`${config.api.HOST}/users/favorites`, { favorites });
			updateUserData();
		} catch (err) {
			console.error(err.message);
		}
	};

	const transferPoints = async (
		teamId,
		points,
		senderId,
		receiverIds,
		notes
	) => {
		const response = await clientNew.post(
			`${config.teams.HOST}/teams/reward/${teamId}`,
			{
				recipients: receiverIds,
				points,
				notes,
			}
		);
		if (response.data) {
			updateUserData();
			return { status: 'success' };
		}
	};

	const updateProfilePic = async (directUrl) => {
		try {
			directUrl && setUserData({ ...userData, src: directUrl });
		} catch (err) {
			console.error(err.message);
		}
	};

	const createTeam = async ({ teamName, junoDay }) => {
		try {
			const response = await clientNew.post('/teams', {
				teamName,
				junoDay,
			});
			updateUserData();
			return response.data.team.id;
		} catch (err) {
			console.error(err.message);
		}
	};

	return (
		<UserContext.Provider
			value={{
				userData,
				walletPoints,
				loading,
				changeCurrentTeam,
				updateDefaultPaymentMethodTeam,
				addPaymentMethodTeam,
				createBookingUser,
				cancelBookingUser,
				updateUserFields,
				updateUserFieldsBatch,
				changeFavorite,
				transferPoints,
				updateProfilePic,
				createTeam,
				updateUserData,
				deletePaymentMethodTeam,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
